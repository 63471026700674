<template>
  <div class="app-toasts">
    <transition-group name="slide-x-transition">
      <AppToast v-for="(toast, index) in toasts" :key="toast.id" :toast="toast" :index="index" />
    </transition-group>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AppToasts',
  components: {
    AppToast: _ => import('@/components/UI/AppToast'),
  },
  setup(props, { root: { $store } }) {
    const toasts = $store.state.notifications.toasts

    return {
      toasts,
    }
  },
})
</script>
